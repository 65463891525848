'use client';

import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Link from '@/components/Link';

const ErrorPage = () => {
  return (
    <Stack
      my={'auto'}
      gap={4}
      alignItems="center"
      height="600px"
      direction="column"
      justifyContent={'center'}
    >
      <Typography variant="h2">An error occurred</Typography>
      <Button LinkComponent={Link} href="/">
        Return Home
      </Button>
    </Stack>
  );
};

export default ErrorPage;
